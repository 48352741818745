



















































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { UniqueIdGenerator } from "@/utils/uid";
import {
  getCourseTitle,
  getGroupName,
  getObjectByProp,
  getUserNameByEmail,
} from "@/utils/utils";
import { groupCollection } from "@/utils/db";
import {
  CompanyCourseAssignment,
  CoachingSession,
  CoachingGroup,
  RhoUser,
  Progress,
  Batch,
  Coach,
  Company,
} from "@/models/models";
import { Card } from "@/models/Card";
import { Course } from "@/models/Course";
import { Chapter } from "@/models/Chapter";
import { Subchapter } from "@/models/Subchapter";
import { mapGetters } from "vuex";
import { enableUntilChapters } from "@/utils/presets";
import _ from "lodash";
import CardPreviewEntry from "@/components/CardPreview/CardPreviewEntry.vue";
import moment from "moment";
export default Vue.extend({
  components: { CardPreviewEntry },
  data: () => ({
    editedGroup: {},
    coachingFormValid: false,
    enableUntilChapters: enableUntilChapters,
    usePreset: true,
    useCoachCollection: true,
    tab: null,
    currentCoaching: null as string | null,
    addGroupDialog: false,
    addGroupData: {
      addGroupName: "",
      addGroupCourse: "",
      addGroupCoachImage: "",
      addGroupCoachName: "",
      numberCoachings: 5,
      weeklyFrequency: 2,
      firstCoachingStart: "",
      firstCoachingDuration: 1.5,
      coachObject: {} as Coach,
    },
    editGroupDialog: false as boolean,
    addUserDialog: false,
    addUserCompany: {} as Company,
    addUserEmail: null,
    dialog: false,
    email: "",
    editedIndex: -1,
    editedCoaching: {} as CoachingSession,
    editedCoachingDuration: 1.5,
    defaultCoaching: {
      coach: "",
      coachImage: "",
      courseId: "",
      enableUntilChapter: "",
      end: "",
      start: "",
    },
    headers: [
      { text: "", value: "image" },
      { text: "Coach", value: "coach" },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Last chapter", value: "enableUntilChapter" },
      { text: "CourseId", value: "courseId", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    courseChipColor: "blue",
  }),
  computed: {
    ...mapGetters([
      "rhoUsers",
      "progress",
      "userProgressCalculation",
      "allCourses",
      "batches",
      "groups",
      "sortedGroups",
      "coaches",
      "sortedCompanies",
    ]),
    addUserFormValid(): boolean {
      if (this.addUserEmail && this.addUserCompany) {
        const courseValid =
          this.addUserCompany.courses.filter((course) => {
            const email = this.addUserEmail || "";
            if (
              course.courseId === this.currentGroup.course &&
              course.users.includes(email)
            ) {
              return true;
            } else {
              return false;
            }
          }).length > 0;

        let emailNotInGroup = false;
        if (this.currentGroup.users.length > 0) {
          emailNotInGroup = !this.currentGroup.users.includes(
            this.addUserEmail
          );
        } else {
          emailNotInGroup = true;
        }
        return courseValid && emailNotInGroup;
      }
      return false;
    },
    formTitle(): string {
      return this.editedIndex === -1
        ? "New Coaching Session"
        : "Edit Coaching Session";
    },
    /** Return all course ids like (c1,c2) without locale postfix (_de) */
    allCourseIds(): string[] {
      let ids = new Set<string>(
        this.allCourses.map((i: { id: string }) => i.id.split("_")[0])
      );
      return [...ids];
    },
    allCourseObjects(): any[] {
      let res: any[] = [];
      if (this.allCourseIds) {
        res = this.allCourseIds.map((courseId) => {
          let course = this.allCourses.find(
            (c) => c.id.split("_")[0] === courseId
          );
          return { title: course.title, id: courseId };
        });
      }
      return res;
    },
    currentGroupCourse(): Course {
      return this.allCourses.find(
        (c: Course) => c.id.split("_")[0] === this.currentGroup.course
      );
    },
    currentGroupCoaches(): Coach[] {
      if (this.currentGroup.coaches) {
        return this.coaches.filter((c) => {
          return this.currentGroup.coaches.includes(c.id);
        });
      } else {
        return [];
      }
    },
    currentGroupBatch: {
      get(): Batch | undefined {
        let batch = this.batches.find((b: Batch) => {
          if (b.groups.includes(this.currentGroup.id)) {
            return b;
          }
        });
        return batch;
      },
    },
    relevantChapters(): Chapter[] {
      let chapters: Chapter[] = [];
      if (this.currentGroup) {
        let course = this.allCourses.find(
          (c: Course) => c.id.split("_")[0] === this.currentGroup.course
        );
        let start;
        let end;
        if (this.currentCoaching) {
          let index = this.currentGroup.coachings.findIndex((c) => {
            return c.id === this.currentCoaching;
          });
          if (index > 0) {
            start = this.currentGroup.coachings[index - 1].enableUntilChapter;
          }
          end = this.currentGroup.coachings[index].enableUntilChapter;
        }

        if (course?.modules) {
          let started = false;
          course.modules.forEach((module) => {
            if (!start) {
              // started is not defined because its the first coaching
              start = module.chapters[0].id;
              started = true;
            }
            for (let chapter of module.chapters) {
              if (started) {
                chapters.push(chapter);
              } else {
                // beginn
                if (chapter.id === start) {
                  started = true;
                }
              }
              if (end) {
                if (chapter.id === end) {
                  started = false;
                  break;
                }
              }
            }
          });
        }
      }
      return chapters;
    },
    relevantCards(): any[] {
      let cards: any[] = [];
      this.relevantChapters.forEach((chapter: Chapter) => {
        chapter.subchapters.forEach((subchapter: Subchapter) => {
          subchapter.cards.forEach((card: Card) => {
            cards.push({ ...card, subchapter: subchapter, chapter: chapter });
          });
        });
      });
      return cards;
    },
    filteredRelevantCards(): any[] {
      let res = this.relevantCards.filter(this.filterRelevantCards);
      res = res.map((item) => {
        item.inputs = this.getCardInputs(item);
        item.notes = this.getCardNotes(item.id);
        return item;
      });
      return res;
    },
    relevantCardIds(): string[] {
      let cardIds: string[] = [];
      this.relevantChapters.forEach((chapter: Chapter) => {
        chapter.subchapters.forEach((subchapter: Subchapter) => {
          subchapter.cards.forEach((card: Card) => {
            cardIds.push(card.id);
          });
        });
      });
      return cardIds;
    },
    currentCoachingImage(): string {
      return this.currentGroup.coachings.map((c) => c.coachImage)[0];
    },
    currentGroup(): CoachingGroup | any {
      return this.groups.find(
        (item: CoachingGroup) => item.id == this.$route.params.coachingGroupId
      );
    },
    currentUsers: {
      get(): { email: string; name: string; progress: any }[] {
        let currentUsers = [];
        if (this.currentGroup && this.currentGroup.users.length > 0) {
          currentUsers = this.currentGroup.users.map((email) => {
            return new Object({
              email: email,
              name: getUserNameByEmail(email),
            }) as {
              name: string;
              email: string;
            };
          });
        }
        return currentUsers;
      },
    },
    allUsers: {
      get(): [] {
        if (this.currentGroup) {
          return this.currentGroup.users.reduce(
            (a: [{ email: string }], v: string) => [
              ...a,
              { email: v, courses: this.userCourses(v) },
            ],
            []
          );
        } else {
          return [];
        }
      },
    },
    courses(): CompanyCourseAssignment[] {
      return this.currentGroup?.courses || [];
    },
  },
  watch: {
    "currentGroup.id": function (newId, oldId) {
      if (newId != oldId) {
        this.setCurrentCoaching();
      }
    },
    dialog: {
      handler(val) {
        val || this.close();
      },
    },
  },
  methods: {
    addUserToGroup() {
      const newUsers = _.cloneDeep(this.currentGroup.users);
      newUsers.push(this.addUserEmail);

      this.$store
        .dispatch("updateDocument", {
          document: groupCollection.doc(this.currentGroup.id),
          data: { users: newUsers },
        })
        .then(() => {
          this.addUserDialog = false;
          this.addUserEmail = null;
        });
    },
    moment: function () {
      return moment();
    },
    filterRelevantCards(card) {
      const inputs = this.getCardInputs(card);
      const notes = this.getCardNotes(card.id);
      if (
        (inputs.length > 0 || (notes && notes.length > 0)) &&
        (["freeinput", "multiple_choice_w_answer", "chart"].includes(
          card.type
        ) ||
          card?.elements?.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    setCurrentCoaching(): void {
      this.currentCoaching = null;
      let today = new Date();
      if (this.currentGroup?.coachings) {
        for (let i = 0; i < this.currentGroup.coachings.length; i++) {
          let c = this.currentGroup.coachings[i];
          if (!c.start) {
            continue;
          } else {
            let coachingDate = new Date(c.start);
            if (today < coachingDate) {
              this.currentCoaching = c.id;
              break;
            }
          }
        }
      }
    },
    resetAddGroupData(): void {
      this.addGroupData = {
        addGroupName: "",
        addGroupCourse: "",
        addGroupCoachImage: "",
        addGroupCoachName: "",
        numberCoachings: 5,
        weeklyFrequency: 2,
        firstCoachingStart: "",
        firstCoachingDuration: 1.5,
        coachObject: {} as Coach,
      };
    },
    getCardNotes(
      cardId: string
    ): { user: { email: string; progress: any }; note: any }[] {
      //  return all user notes for one card
      let res: { user: { email: string; progress: any }; note: any }[] = [];
      this.currentUsers.forEach((user) => {
        user["progress"] = this.getProgress(user);
        let note = user.progress?.notes?.find((n) => n.cardId === cardId);
        if (note) {
          res.push({ user: user, note: note.note });
        }
      });
      return res;
    },
    getCardInputs(card) {
      // return all user inputs for one card
      let res: { user: { email: string; progress: any }; answer: any }[] = [];
      this.currentUsers.forEach((user) => {
        user.progress = this.getProgress(user);
        let progressCard = user.progress?.cards?.find((c) => c.id === card.id);
        if (progressCard) {
          if (progressCard?.elements == null) {
            //Card v1
            res.push({
              user: user,
              answer: progressCard?.payload?.answer || null,
            });
          } else if (progressCard?.elements?.length > 0) {
            //Card v2
            let elementResponses = progressCard.elements.map((progressElement) => {
              let answer;

              switch(progressElement?.type) {
                case "question": {
                  let answers = card.elements.find((cardElement) => cardElement.id === progressElement.id).payload.questions;
                  //Workaround if model is like v1 course
                  if(progressElement.payload.answerIndex != null){
                    answer = answers[progressElement.payload.answerIndex];
                  } else {
                    answer = progressElement.payload.answer;
                  }
                  break;
                }
                case "sum": {
                  let str = "";
                  let answers = Object.entries(progressElement.payload.answers);
                  answers.forEach(([key, value]) => str += key + ": " + value + "; ");
                  answer = str;
                  break;
                }
                case "calculation": {
                  let answers = Object.entries(JSON.parse(progressElement.payload.results));
                  let options = card.elements?.find((cardElement) => cardElement.id === progressElement.id)?.payload?.calculations;
                  let str = "";
                  if (options != null) {
                    answers.forEach(([key, value]) => {
                      if(value != 0){
                        let title = options?.find((e) => e.storeId === key).title;
                    str += title + ": " + value + "; ";
                      }
                  });
                  answer = str;
                  } else {
                    answer = progressElement?.payload?.results
                  }
                  break;
                }
                default: 
                    answer = progressElement?.payload?.answer || progressElement?.payload?.answers || progressElement?.payload;
                    break;
              }

              return { user: user, answer: answer};
            });
            res.push(...elementResponses);
          }
        }
      });
      return res;
    },
    getCard(cardId: string): Card | undefined {
      // get course and search for card in course
      let course = this.allCourses.find(
        (c: Course) => c.id.split("_")[0] === this.currentGroup.course
      );
      if (course) {
        return getObjectByProp(course, "id", cardId);
      }
    },
    getTask(card: Card) {
      if (card) {
        if (card.type === "freeinput") {
          return card.payload?.task?.split("**").join("");
        } else if (card.type === "multiple_choice_w_answer") {
          return (card.description + " " + card.payload?.description)
            .split("**")
            .join("");
        } else if (card.type === "chart") {
          return card.payload?.legendTitle;
        }
      }
    },
    saveCoachingSession() {
      if (this.currentGroup) {
        if (!this.editedCoaching.id) {
          this.editedCoaching.id = UniqueIdGenerator.generate();
        }
        //Override defaults
        this.editedCoaching = {
          ...this.defaultCoaching,
          ...this.editedCoaching,
        };
        //Convert localtime to utc
        if (this.editedCoaching.start) {
          let localStart = new Date(this.editedCoaching.start);
          let utcStart = moment.utc(localStart).format("YYYY.MM.DD HH:mm:SS");
          this.editedCoaching.start = utcStart;

          let localEnd = moment(localStart).add(
            this.editedCoachingDuration * 60,
            "minutes"
          );
          let utcEnd = moment.utc(localEnd).format("YYYY.MM.DD HH:mm:SS");
          this.editedCoaching.end = utcEnd;
        }
        if (this.editedIndex > -1) {
          this.currentGroup.coachings[this.editedIndex] = this.editedCoaching;
        } else {
          this.currentGroup.coachings.push(this.editedCoaching);
        }
        this.$store
          .dispatch("updateDocument", {
            document: groupCollection.doc(this.currentGroup.id),
            data: { coachings: this.currentGroup.coachings },
          })
          .then(() => {
            this.close();
          });
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedCoaching = Object.assign({}, this.defaultCoaching);
        this.editedCoachingDuration = 1.5;
        this.editedIndex = -1;
      });
    },
    editGroup() {
      if (this.currentGroup) {
        this.editedGroup = { ...this.currentGroup };
        this.editGroupDialog = true;
      }
    },
    editCoachingSession(item: CoachingSession) {
      if (this.currentGroup) {
        this.editedIndex = this.currentGroup.coachings.indexOf(item);
        this.editedCoaching = Object.assign({}, item);
        if (this.editedCoaching.start) {
          this.editedCoaching.start = moment
            .utc(this.editedCoaching.start)
            .local()
            .format("YYYY.MM.DD HH:mm:SS");
        }
        if (this.editedCoaching.end) {
          this.editedCoaching.end = moment
            .utc(this.editedCoaching.end)
            .local()
            .format("YYYY.MM.DD HH:mm:SS");
        }
        //calc current duration
        if (this.editedCoaching.start && this.editedCoaching.end) {
          let startDate = new Date(this.editedCoaching.start);
          let endDate = new Date(this.editedCoaching.end);
          let hours =
            Math.round(
              (Math.abs(startDate.getTime() - endDate.getTime()) / 3.6e6) * 2
            ) / 2;
          this.editedCoachingDuration = hours;
        }

        this.dialog = true;
      }
    },
    deleteCoachingSession(item: CoachingSession) {
      if (confirm("Do you really want to delete this Coaching Session?")) {
        let index = this.currentGroup.coachings.findIndex(
          (coaching) => coaching.id == item.id
        );
        if (index >= 0) this.currentGroup.coachings.splice(index, 1);
        this.$store.dispatch("updateDocument", {
          document: groupCollection.doc(this.currentGroup.id),
          data: { coachings: this.currentGroup.coachings },
        });
      }
    },
    /** Fill coachings if preset */
    _presetCoachings(_defaultCoaching) {
      let coachings;
      if (this.usePreset) {
        if (this.useCoachCollection && this.addGroupData.coachObject) {
          this.addGroupData.addGroupCoachName =
            this.addGroupData.coachObject.name;
          this.addGroupData.addGroupCoachImage =
            this.addGroupData.coachObject.image;
        }
        _defaultCoaching.coach = this.addGroupData.addGroupCoachName;
        _defaultCoaching.coachImage = this.addGroupData.addGroupCoachImage;
        coachings = Array(this.addGroupData.numberCoachings).fill({
          ..._defaultCoaching,
        });
        coachings = coachings.map((element) => {
          return { ...element, id: UniqueIdGenerator.generate() };
        });
        // Check if preset exists and matches number of Sessions
        // If yes: fill enableUntilChapter
        if (
          this.enableUntilChapters[this.addGroupData.addGroupCourse]?.length ===
          this.addGroupData.numberCoachings
        ) {
          coachings = coachings.map((element, index) => {
            let chapter =
              this.enableUntilChapters[this.addGroupData.addGroupCourse][index];
            return {
              ...element,
              enableUntilChapter: chapter,
            };
          });
        }
        if (
          this.addGroupData.weeklyFrequency &&
          this.addGroupData.firstCoachingStart
        ) {
          let s = moment(new Date(this.addGroupData.firstCoachingStart));
          let e = moment(new Date(this.addGroupData.firstCoachingStart)).add(
            this.addGroupData.firstCoachingDuration * 60,
            "minutes"
          );

          coachings = coachings.map((element, index) => {
            let _s = s
              .clone()
              .add(index * 7 * this.addGroupData.weeklyFrequency, "d");
            let _e = e
              .clone()
              .add(index * 7 * this.addGroupData.weeklyFrequency, "d");
            return {
              ...element,
              start: moment.utc(_s).format("YYYY.MM.DD HH:mm:SS"),
              end: moment.utc(_e).format("YYYY.MM.DD HH:mm:SS"),
            };
          });
        }
      } else {
        coachings = [];
      }
      return coachings;
    },
    addGroup() {
      let _defaultCoaching = { ...this.defaultCoaching };
      _defaultCoaching.courseId = this.addGroupData.addGroupCourse;
      let coachings = this._presetCoachings(_defaultCoaching);

      let group: CoachingGroup = {
        users: [],
        course: this.addGroupData.addGroupCourse,
        coachings: coachings,
        name: this.addGroupData.addGroupName,
      };

      if (this.useCoachCollection && this.addGroupData.coachObject.email) {
        group.coaches = [this.addGroupData.coachObject.email];
      }

      this.$store
        .dispatch("addDocument", {
          data: group,
          collection: groupCollection,
        })
        .then((res) => {
          //Set id as property, as it is requiered by our apps
          this.$store.dispatch("updateDocument", {
            document: res,
            data: { id: res.id },
          });
          this.resetAddGroupData();
          this.addGroupDialog = false;
          this.$router.push("/coaching-group/" + res.id);
        });
    },
    deleteCurrentGroup() {
      if (this.currentGroup) {
        if (confirm("Do you really want to delete this group?")) {
          this.$store.dispatch("deleteDocument", {
            document: groupCollection.doc(this.currentGroup.id),
          });
        }
      }
    },
    saveGroup() {
      this.$store
        .dispatch("updateDocument", {
          data: this.editedGroup,
          document: groupCollection.doc(this.currentGroup.id),
        })
        .then(() => {
          this.editedGroup = {};
          this.editGroupDialog = false;
        });
    },
    getGroupName(group: CoachingGroup): string {
      return getGroupName(group);
    },
    getCourseTitle(courseId: string): string {
      return getCourseTitle(courseId);
    },
    getCoachImage(group: CoachingGroup): string {
      let image = "";
      if (group.coachings.length > 0) {
        image = group.coachings[0].coachImage;
      }
      return image;
    },
    userCourses(email: string): string[] {
      let courseIds = [] as string[];

      if (this.courses) {
        let courseAssignments = this.courses.filter(
          (course: CompanyCourseAssignment) => course.users.indexOf(email)
        );

        courseIds = courseAssignments.reduce(
          (acc: string[], course: { courseId: string }) => [
            ...acc,
            course.courseId,
          ],
          []
        );
      }

      return courseIds;
    },
    getProgress(user: { email: string }) {
      let rhoUser = this.rhoUsers.find(
        (item: RhoUser) => item.email === user.email
      );

      if (rhoUser) {
        return this.progress.find(
          (item: Progress) =>
            item.userId == rhoUser?.userId &&
            item.courseId == this.currentGroup.course
        );
      } else {
        return undefined;
      }
    },
    getTotalProgress(user) {
      // % done chapters until selected coaching
      let rhoUser = this.rhoUsers.find(
        (item: RhoUser) => item.email === user.email
      );
      if (rhoUser) {
        let userProgress = this.userProgressCalculation.find(
          (p) =>
            p.userId === rhoUser?.userId &&
            p.courseId === this.currentGroup.course
        );
        if (userProgress) {
          if (this.currentCoaching) {
            let coaching = this.currentGroup.coachings.find(
              (c) => c.id == this.currentCoaching
            );
            if (coaching?.enableUntilChapter === "") {
              return Math.round(userProgress.courseProgress.finished * 100);
            } else {
              let chapterProgress: number[] = [];
              userProgress.courseProgress.modules.forEach((module) => {
                for (let chapter of module.chapters) {
                  if (
                    this.relevantChapters.map((c) => c.id).includes(chapter.id)
                  ) {
                    chapterProgress.push(Math.round(chapter.finished * 100));
                  }
                }
              });
              let sum = chapterProgress.reduce((a, b) => a + b, 0);
              return String(Math.round(sum / chapterProgress.length));
            }
          } else {
            // No coaching selected -> return all progress
            return Math.round(userProgress.courseProgress.finished * 100);
          }
        } else {
          return "noUserProgress";
        }
      } else {
        return "noRhoUser";
      }
    },
  },
});
